import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
import { Layout, SEO } from '../../../components/structure';
import { Content, Hero } from "../../../components/blocks"
import styled, { css } from "styled-components"
import { brand, font, mq } from "../../../styles";
import GatsbyImage from "gatsby-image";
import { graphql, useStaticQuery } from "gatsby";


const Header = styled.div`


${ font.imports.sherika }

  width: 90%;
  max-width: 1100px;
  text-align: left;
  padding-top 80px;

  h2 {
    font-family: ${font.family.sherika};
    font-size: 56px;
    font-weight: 700;
    padding: 20px 0;
    color: ${brand.colors.pg_primary_dark};
    display: inline-block;
    
    @media only screen and ${mq.maxMd} {
      text-align: center;
      font-size: 42px;
    }

    .color-emphasis {
      color: ${brand.colors.pg_orange};
    }
  }

}
`;

const Copy = styled.div`
    margin-top: 50px;
    margin-bottom: 20px;
  p {
    color: ${brand.colors.pg_primary_dark};
    font-size: 22px;
  } 
`;

const PrivacyPolicy = styled.div`
  width: 90%;
  max-width: 1100px;
  margin: auto;
`
const PrivacyPage = () => {


  return(
    <Layout>
    <SEO title="Règles de Confidentialité | Plusgrade" 
          description={'Plusgrade - Règles de Confidentialité'}
      />
      <Content whiteBG noMargin>
        <PrivacyPolicy>
          <Header>
              <h2>Plusgrade<span className={"color-emphasis"}> - Règles de Confidentialité</span></h2>
          </Header>
          <Copy>
            <p>
            Les présentes Règles de Confidentialité visent à vous indiquer quelles informations nous collectons des visiteurs de notre site web Plusgrade et s’applique au www.plusgrade.com (incluant toutes sous-domaines, le “Site”), nos services et/ou n’importe quelles connections reliant à nos événements, nos activités de ventes et nos activités de commercialisation.  Cette politique décrit de quelle façon Plusgrade traite et protège les informations personnelles que vous nous fournissez ainsi que vos droits et options en matière de respect de vos renseignements personnels selon nos capacités en tant que contrôleur de la protection des données.
            </p>
            <p>
            Cliquez sur les liens ci-dessous afin de lire les sommaires de chaque sujet. Si vous désirez apprendre plus d’information détaillée, cliquez sur “apprendre plus” dans la section.
            </p>
            <div className="FAQ-expandable-container">
        {/** Question # 1 */}
              <QuestionSection 
                question = {"Quel est le but de ces Règles de Confidentialité?"}
                answer={
                  <div>
                    <p>
                    Plusgrade offre un produit logiciel et des services (ensemble, “Services”) qui permettent à des compagnies dans l’industrie du voyage (c.-à-d. “les partenaires commerciaux” de Plusgrade) d’offrir à leurs clients (“voyageurs”) l’opportunité de pouvoir surclasser leur expérience de voyage en sujet des règles spécifiques à chacun de nos partenaires, ainsi qu’à la disponibilité de l’offre.
                    </p>
                    <p>
                    Nous et nos partenaires commerciaux portons une grande importance à la protection de vos renseignements personnels. C’est pour cette raison que nous sommes engagés à assurer la sécurité et confidentialité des informations personnelles que nous recevons et traitons de nos partenaires commerciaux dans le cadre des services que nous leur fournissons. Nous sommes aussi engagés à fournir un produit logiciel qui répond aux meilleurs pratiques de l’industrie et exigences réglementaires pour toutes protections de données et d’informations privées.
                    </p>
                    <p>
                    Ces Règles de Confidentialité a pour but d’informer les partenaires commerciaux de Plusgrade et leurs voyageurs de nos pratiques portant à la protection des données privées. Cette politique sur la protection des renseignements personnels s’applique aussi aux données que nous collectons des visiteurs du Site et en connection à nos événements, nos activités de ventes et nos activités de commercialisation.
                    </p>
                  </div>
                }

                learnMoreContent={
                  <div>
                    <p>
                      Ces Règles de Confidentialité expliquent la manière dont Plusgrade traite des données personnelles, comment nous les protégeons dans le cadre de nos affaires et de vos droits.
                    </p>
                    <p>
                    Les informations comprises dans cette politique sur la protection des renseignements personnels incluent:
                    </p>
                    <ul>
                      <li>Les détails de contact de Plusgrade, incluant notre délégué à la protection des données et notre représentant dans l’union européenne;</li>
                      <li>Les raisons pour lesquelles nous traitons les données personnelles et nos bases légales pour ce traitement;</li>
                      <li>Les types de données personnelles que nous traitons;</li>
                      <li>Les catégories des tierces parties à qui nous pouvons divulguer les données;</li>
                      <li>Les juridictions dans lesquelles nous traitons les données personnelles et les sauvegardes mises en place pour tous transferts internationaux de données personnelles;</li>
                      <li>Les critères utilisés pour déterminer la période qu’on garde les données personnelles;</li>
                      <li>Les droits portant à la protection des renseignements personnels qui s’appliquent aux voyageurs dont Plusgrade obtient et traite leurs données personnelles de la part de nos partenaires commerciaux, incluant le droit de porter plainte avec une autorité compétente en manière de protection de la vie privée.</li>
                    </ul>
                  </div>
                }
              />
          {/** Question # 2 */}
              <QuestionSection 
                question = {"Quelles données personnelles sont traitées par Plusgrade?"}
                answer={
                  <div>
                    <p>
                      En tant que fournisseur de service tiers à des compagnies dans l’industrie de voyage (c.-à-d. les “partenaires commerciaux” de Plusgrade), Plusgrade reçoit généralement des données personnelles qui étaient initialement divulguées par les voyageurs de nos partenaires commerciaux reliant à une réservation de voyage ou un achat, pour le but de pouvoir fournir nos Services à nos partenaires commerciaux selon le contrat de service applicable.
                    </p>
                    <p>
                      Plusgrade peut aussi recevoir des informations portant aux demandes de service venant des voyageurs (préférences de repas de vol ou classe de voyage, par exemple) de nos partenaires commerciaux. Quand telles informations sont traitées par Plusgrade et sont associées à un individuel, on les considère comme étant des données personnelles afin de pouvoir accommoder à ces Règles de Confidentialité.
                    </p>
                  </div>
                }
                learnMoreContent={
                  <div>
                    <ol>
                      <li>
                      Données Personnelles Concernant les Voyageurs:
                        <p>
                        Nous traitons les données personnelles fournies par les voyageurs reliant à une réservation ou un achat de services de voyage provenant des compagnies dans l’industrie de voyage (c.-à-d. les “partenaires commerciaux” de Plusgrade), de la part de nos partenaires commerciaux. Ceci inclut typiquement ou peut inclure les prochaines catégories de données personnelles:
                        </p>
                        <ul>
                          <li>
                            Informations de contact, qui peut inclure votre nom et votre adresse courriel
                          </li>
                          <li>
                            Informations d’authentification, qui peut inclure vos informations de passeport, et votre numéro du programme de fidélité
                          </li>
                          <li>
                            Préférences liées au voyage et informations sur votre niveau de fidélité
                          </li>
                          <li>
                            Toutes communications avec nos partenaires commerciaux, incluant communications par courriel
                          </li>
                          <li>
                            Données de localisation, incluant les aéroports ou ports de départ et de destination
                          </li>
                          <li>
                            Identifiants en ligne, incluant l’adresse IP
                          </li>
                        </ul>
                      </li>

                      <li>
                      Données Personnelles Concernant les Partenaires Commerciaux, Prospects et Visiteurs du Site:
                      <p>
                        <strong className="italized">Données Personnelles Que Vous Nous Fournissez Nous</strong> collectons les informations que vous entrez sur notre Site ou que vous nous envoyez électroniquement, par exemple, quand vous demandez pour de l’information, incluant une démo de produit, quand vous vous inscrivez à un webinaire ou autre événement, ou quand vous souscrivez à une liste de diffusion par courriel. Alors que le type de données qu’on collecte dépend de la nature de l’enquête, ceci inclut typiquement votre nom, détails de contact, informations de compagnie et numéro de téléphone. Nous pouvons aussi collecter des données personnelles que vous nous fournissez quand vous assistez à un de nos évènements (tel que vos détails de contact), durant vos appels téléphoniques avec des représentants de ventes ou quand vous contactez le service à la clientèle.
                      </p>
                      <span className="italized">Données Collectées Automatiquement</span>
                      <p>
                        <strong className="italized">Données Log.</strong> Quand vous visitez notre Site, nos serveurs enregistrent des informations (“données log”), incluant des informations que votre navigateur envoie automatiquement lorsque vous visitez le Site. Ces données log incluent votre adresse de protocole Internet (“IP”) (d'où on a la capacité de voir le pays depuis lequel vous vous connectez lors de votre visite du Site), type de navigateur et réglages, la date et l’heure de votre demande.
                      </p>
                      <p>
                        <strong className="italized">Cookies.</strong> Quand vous visitez notre Site, nous et les fournisseurs de service agissant en notre nom allons automatiquement collecter des informations à propos de vous en utilisant des cookies (petits fichiers texte placés sur votre appareil).
                      </p>
                      </li>
                    </ol>
                  </div>

                }
              />
              {/** Question # 3 */}
              <QuestionSection 
                question = {"D'où proviennent les données personnelles obtenues par Plusgrade?"}
                answer={
                  <ol>
                    <li>
                    Données Personnelles Concernant les Voyageurs:
                      <p>Plusgrade obtient des données personnelles provenant des voyageurs reliant à une réservation ou un achat des services de voyage provenant des compagnies dans l’industrie de voyage (c.-à-d. les “partenaires commerciaux” de Plusgrade), avec le but de permettre aux voyageurs de participer à un surclassement de leur réservation et autres services fournis par Plusgrade selon le contrat de service applicable.</p>
                    </li>
                    <li>
                      Données Personnelles Concernant les Partenaires Commerciaux, Prospects et Visiteurs du Site:
                      <p>Plusgrade obtient les données personnelles que l’on traite, range et divulgue quand on nous fournit ces données ou quand on les collecte, comme décrit dans le paragraphe sous la section “Quelles données personnelles sont traitées par Plusgrade?”.</p>
                    </li>
                  </ol>
                }
              />
              {/** Question # 4 */}
              <QuestionSection 
                question = {"Quels sont les bases légales et buts pour lesquels Plusgrade traite des données personnelles?"}
                answer={
                  <div>
                    <p>
                      Nous utilisons les informations des voyageurs afin de bien fournir nos Services aux compagnies dans l’industrie de voyage avec qui l’on travaille (c.-à-d. les “partenaires commerciaux” de Plusgrade). Nous allons aussi utiliser ces informations pour améliorer les caractéristiques de nos produits ou pour fournir des améliorations à nos services ainsi que pour soutenir nos partenaires commerciaux dans leur programme actuel et pour toute croissance continue en vertu de notre contrat de service.
                    </p>
                    <p>
                      L’utilisation des données personnelles d’un individu sera limitée aux fins pour lesquelles ils ont été recueillies par nos partenaires commerciaux - afin de fournir les surclassements de réservation et autres services associés - comme convenu dans notre contrat de service avec nos partenaires commerciaux.
                    </p>
                    <p>
                      C’est la responsabilité de nos partenaires commerciaux d’assurer qu’ils obtiennent les données personnelles de leurs voyageurs légalement. Puisque Plusgrade fournit à nos partenaires commerciaux une technologie qui leur permettent d’offrir des services à leurs voyageurs, quand les voyageurs souhaitent de changer leurs options concernant leurs données personnelles, Plusgrade traitera ces souhaits de la part de nos partenaires commerciaux et en vertu de leurs instructions.
                    </p>
                    <p>
                      Nous utilisons les données personnelles de nos partenaires commerciaux, prospects et visiteurs de Site au besoin lorsqu’on répond aux demandes de renseignement par rapport à nos services, la réalisation du présent contrat, l’envoi des courriels promotionnels et toutes autres communications (si vous acceptez de recevoir ces communications) et lorsque c’est nécessaire pour nos intérêts légitimes dans le traitement.
                    </p>
                  </div>
                }

                learnMoreContent={
                  <div>
                    <ol>
                      <li>
                        Données Personnelles Concernant les Voyageurs:
                        <p>
                        Plusgrade utilise les données personnelles des voyageurs au cours de nos provisions de services à nos partenaires commerciaux pour but de fournir les services aux partenaires conformément au contrat de service applicable: dans la mesure nécessaire pour ces buts, nous prenons des étapes raisonnables afin d’assurer des données personnelles qui sont précises, complètes, actuelles et fiable pour son fiables prévu. Ces étapes consistent à suivre les processus internes de Plusgrade afin de gérer les demandes de surclassement provenant de nos partenaires commerciaux, d’une manière efficace et en temps opportun, les données personnelles des voyageurs reçues par eux.
                        </p>
                      </li>
                      <li>
                      Données Personnelles Concernant les Partenaires Commerciaux, Prospects et Visiteurs du Site:
                        <p>
                          <strong className={"italized"}>Pour vous fournir les Services et pour répondre à vos demandes</strong> Lorsque vous désirez des informations concernant les Services (par exemple, quand vous demandez pour une démo ou quand vous nous demandez d’envoyer des offres ou des informations rapportant aux prix) ou lorsque vous vous enregistrez à un webinaire ou un événement, nous utiliserons votre information de contact pour répondre à votre demande. Pour toutes données de sujet UE, cet usage est nécessaire afin de répondre à ou implémenter votre demande.
                        </p>
                        <p>
                        Nous utilisons des données liées aux comptes fournies par nos partenaires commerciaux en connection avec l’achat, l’inscription, l’utilisation ou le support du compte avec notre partenaire (tel que les noms d’utilisateur, les adresses courriels, et les détails de facturation) afin de vous fournir avec l’accès de nos Services et/ou le Site, de vous contacter concernant votre usage des Services et/ou le Site ou de vous notifier lors d’un changement important des Services et/ou le Site. Pour toutes données de sujet UE, cet usage est nécessaire pour la performance du contrat entre vous et nous.
                        </p>
                        <p>
                          <strong className={"italized"}>Pour des raisons de commercialisation.</strong> Nous utiliserons votre adresse courriel pour vous envoyer des informations reliant à nos nouveaux produits et services, événements à venir et toutes autres promotions. Vous pouvez annuler ces courriels en suivant les instructions contenues dans chaque courriel promotionnel qu’on vous envoie. Nos représentants de ventes peuvent aussi utiliser votre numéro de téléphone pour vous contacter directement par téléphone, concernant des nouveaux produits et services, événements à venir et toutes autres promotions.
                        </p>
                        <p>
                        Lorsque requis par loi applicable (par exemple, si vous êtes un sujet de données de l’UE), nous vous enverrons seulement des informations de commercialisation par courriel ou poste, ou nous vous contacterons par téléphone, si on reçoit votre consentement lors du temps que vous nous fournissez avec vos données personnelles (dans tels cas, notre base légale pour le traitement de vos données personnelles sera votre consentement). Si vous nous donnez votre consentement à être contacté pour des raisons de commercialisation, vous avez le droit de retirer votre consentement n’importe quand en suivant les instructions pour vous désinscrire de toutes communications rapportant à la commercialisation provenant de chacun de nos courriels de commercialisation. De plus, si à tout moment vous ne désirez plus recevoir des futures communications de commercialisation ou si vous voulez supprimez votre nom de notre liste de diffusion ou liste d’appels, s’il-vous-plaît nous contacter au
                          <br></br>
                          <a href="mailto:dpo@plusgrade.com">dpo@plusgrade.com</a>
                          <br></br>
                          Veuillez noter que si vous mettez fin à nos communications de commercialisation, nous pouvons toujours vous contacter pour des problèmes concernant nos Services et pour répondre à vos demandes.
                        </p>
                        <p>
                          <strong className={"italized"}>Pour analyser, administrer, supporter et améliorer l’usage du Site et des Services.</strong> Nous utilisons des données relatives à votre usage et à votre interaction avec le Site et les Services, incluant toutes informations que nous obtenons à travers les Cookies, afin d’analyser, administrer, supporter et améliorer votre accès et votre utilisation du Site et des Services. Nous pouvons aussi compiler, anonymiser et/ou agréger vos données personnelles et autres données et utiliser telles données anonymisées et agrégées pour nos affaires, y compris le partage de ces données avec nos affiliés et nos partenaires commerciaux. Cette information agrégat ne vous identifie pas. Pour sujets avec des données UE, cet usage de vos données personnelles est nécessaire pour nos intérêts légitimes afin de comprendre comment le Site et nos Services sont utilisés par vous et afin d’améliorer votre expérience. Vous avez le droit de vous opposer au traitement de vos données personnelles basé sur vos intérêts légitimes, comme décrit dans la section “En ce qui concerne leurs propres données personnelles, quels sont les droits à la vie privée que les individus ont?” ci-dessous.
                        </p>
                        <p>
                        Si vous êtes un sujet de données de l’UE, s’il-vous-plaît voir la section “En ce qui concerne leurs propres données personnelles, quels sont les droits à la vie privée que les individus ont?” ci-dessous pour des informations reliées à vos droits en relation des données personnelles que nous avons à propos de vous.
                        </p>
                      </li>
                    </ol>
                  </div>
                }
              />
              {/** Question # 5 */}
              <QuestionSection 
                question = {"Plusgrade conserve les données personnelles pour combien de temps?"}
                answer={
                  <div>
                    <p>
                      Plusgrade conserve les données personnelles des voyageurs pour la durée de notre contrat de service avec nos partenaires commerciaux, sauf si il y a une demande pour un raccourcissement ou un prolongement de la durée de conservation, requis ou permis par lois applicables.
                    </p>
                    <p>
                      Nous gardons les autres données personnelles sujet à ces Règles de Confidentialité seulement pour une durée raisonnablement nécessaire pour les raisons et buts décrits dans cette politique, ou pour la durée requise par la loi, la plus longue des deux.
                    </p>
                  </div>
                }
              />
              {/** Question # 6 */}
              <QuestionSection 
                question = {"Dans le cadre de nos offres de services, Plusgrade transfert les données personnelles à quel pays?"}
                answer={
                  <div>
                    <p>
                    Dans le cadre de nos offres de services et pour respecter nos obligations contractuelles, nous transférons les données personnelles à l’UE, Canada, les États-Unis et les pays résidentiels des compagnies dans l’industrie de voyage (c.-à-d. les “partenaires commerciaux” de Plusgrade). Nous pouvons également sous-traiter le traitement, ou partager, des données personnelles des voyageurs avec des tierces parties situées dans des pays en dehors de leur pays natal.
                    </p>
                    <p>
                    Nous protégeons cette information durant les transferts transfrontaliers et nous nous conformons avec les règles rapportant aux transferts transfrontaliers dans les manières suivantes:
                    </p>
                    <ul>
                      <li>
                      La Commission Européenne a trouvé que le Canada offre un niveau adéquat de protection pour les données personnelles transférées de l’UE. Cela veut dire que les données personnelles peuvent légalement circuler de l’UE à Plusgrade au Canada sans être sujet à des règles de transfert;
                      </li>
                      <li>
                      Pour conformer aux restrictions des données transférées entre l’UE et les États-Unis, nous utilisons les “clauses contractuelles types” (également connu sous le terme “clauses types”) approuvées par la Commission Européenne.
                      </li>
                    </ul>
                    <p>
                    Si vous désirez obtenir plus d’information à propos des garanties utilisées pour les transferts internationaux des données personnelles par Plusgrade, veuillez nous contacter comme indiqué dans la section “Comment contacter Plusgrade?” ci-dessous.
                    </p>
                  </div>
                }
              />
              {/** Question # 7 */}
              <QuestionSection 
                question = {"Est-ce que Plusgrade partage les données personnelles avec des tierces parties?"}
                answer={
                  <div>
                    <p>
                    Plusgrade partage les données personnelles des voyageurs à des tierces parties seulement si ceci est nécessaire afin de pouvoir compléter notre contrat de service avec les compagnies dans l’industrie de voyage que nous travaillons avec (c.-à-d. les “partenaires commerciaux” de Plusgrade) et en vertu des instructions de nos partenaires commerciaux, ou autrement requis par la loi applicable.
                    </p>
                    <p>
                    Plusgrade partage les données personnelles de nos partenaires commerciaux, prospects et visiteurs du Site avec des tierces parties qui supportent nos opérations commerciales ou tel que requis ou permis par la loi applicable.
                    </p>
                  </div>
                }
                learnMoreContent={
                  <div>
                    <p>
                    Plusgrade peut partager les données personnelles avec des tierces parties dans les circonstances décrites ci-dessous:
                      <ul>
                        <li>
                        Les Tiers Fournisseurs de Services: afin de pouvoir fournir certains services à nos partenaires commerciaux, Plusgrade peut partager des données personnelles avec des tierces parties, par exemple: pour fournir des services de technologie de l’information; pour mener des services de protection contre la fraude; pour traiter les transactions de cartes de crédit; pour fournir des services aux clients; ou pour performer des services de commercialisation. Les fournisseurs de service reçoivent seulement les informations nécessaires pour pouvoir compléter leurs services et ils sont tenus par contrat à protéger la sécurité et la protection des informations que nous fournissons et pour limiter l’usage des informations aux services qui sont fournis.
                        </li>
                        <li>
                        Autorités Gouvernementales ou Autres: Plusgrade peut, sans avis aux partenaires commerciaux, révéler des données personnelles lorsque requis par la loi, une ordonnance du tribunal, à la demande d’autres autorités gouvernementaux ou organismes d’application de la loi, ou de bonne foi que la divulgation serait autrement nécessaire ou avisable incluant, sans limitation:
                          <ul>
                            <li>
                              Pour protéger les droits ou propriétés de Plusgrade ou ses partenaires commerciaux;
                            </li>
                            <li>
                              Lorsque nous avons la raison de croire que la divulgation des informations est nécessaire pour identifier, contacter ou intenter des poursuites judiciaires contre quelqu’un qui cause une source d’interférences avec nos droits et propriétés;
                            </li>
                            <li>
                              Lorsque toutes autres personnes pourraient souffrir par de telles activités.
                            </li>
                          </ul>
                        </li>
                        <li>
                          Transmission d’Entreprises: si Plusgrade ou tous ses actifs sont acquis substantiellement, les données personnelles qui demeurent dans nos bases de données seraient fort probablement transmises aussi en connexion avec telle acquisition. Dans des cas contenant n’importe quel autre type de transaction considérable ou événement tel qu’une fusion, amalgamation, financement, réorganisation, liquidation, ou insolvabilité, les autres parties de la transaction et leurs conseillers professionnels peuvent avoir besoin d’avoir accès aux bases de données de Plusgrade dans le cadre du processus de diligence raisonnable. Dans un tel cas, Plusgrade fournirait seulement ce genre d’accès sous les termes d’un accord de confidentialité. En cours d’achèvement de la transaction ou de l'événement, des données personnelles seront transférées à la compagnie venant aux droits de Plusgrade et seront utilisées aux fins pour lesquelles elles ont été recueillies.
                        </li>
                      </ul>
                    </p>
                  </div>
                }
              />
              {/** Question # 8 */}
              <QuestionSection 
                question = {"En ce qui concerne leurs propres données personnelles, quels sont les droits à la vie privée que les individus ont?"}
                answer={
                  <div>
                    <p>
                    Les sujets de données dans l’union européenne (UE) ont certains droits rapportant au traitement de leurs données personnelles, décrit ci-dessous (à cet effet, les références à l’UE incluent également l’Espace économique européen: l’Islande, le Liechtenstein, la Norvège et, le cas échéant, la Suisse).
                    </p>
                    <ol>
                      <li>
                        Droits des voyageurs:
                        <p>
                          Par rapport à leurs données personnelles, les voyageurs ont le droit de soumettre les demandes suivantes aux compagnies dans l’industrie de voyage (c.-à-d. les “partenaires commerciaux” de Plusgrade) à qui ils ont précédemment fourni leurs données personnelles, sous réserve du droit applicable:
                        </p>
                        <ul>
                          <li>
                            Demande d’accès à et/ou de correction de leurs propres données personnelles
                          </li>
                          <li>
                            Demande de l’effacement de leurs propres données personnelles        
                          </li>
                          <li>
                            Demande aux partenaires commerciaux de Plusgrade d’arrêter le traitement ou de restreindre le traitement de leurs propres données personnelles      
                          </li>
                          <li>
                            Demande aux partenaires commerciaux de Plusgrade de leur fournir leurs données personnelles dans un format structuré, couramment utilisé et lisible par machine et de transmettre ces données à une autre organisation
                          </li>
                        </ul>
                        <p>
                          Sur notification provenant de nos partenaires commerciaux d’une demande d’un voyageur d’exercer leurs droits, nous allons permettre telles demandes afin d’assurer la conformité avec les exigences réglementaires applicables selon le contrat de service avec le partenaire commercial concerné.
                        </p>
                      </li>

                      <li>
                        Droits des partenaires commerciaux, prospects et visiteurs du Site:
                        <p>
                        Sous réserve des lois applicables, vous avez les droits suivants en relation de vos données personnelles:
                        </p>
                        <ul>
                          <li>
                            <strong>Droit d’accès:</strong> Si vous nous demandez, nous confirmerons le traitement de vos données personnelles et, si c’est le cas, nous vous fournirons une copie de ces données personnelles accompagnées de certains autres détails. Si vous désirez des copies supplémentaires, nous aurons peut-être besoin de charger des frais raisonnables.
                          </li>
                          <li>
                            <strong>Droit de rectification:</strong> Si vos données personnelles sont inexactes ou incomplètes, vous avez le droit de nous demander de les corriger ou de les compléter. Dans le cas ou nous avons partagé vos données personnelles avec d’autres, nous les aviserons de cette correction où c’est possible. Si vous nous demandez, et où c’est possible et légal de le faire, nous vous dirons également avec qui nous avons partagé vos données personnelles pour que vous puissiez les contacter directement.
                          </li>
                          <li>
                          <strong>Droit d’effacement:</strong> Vous pouvez nous demander de supprimer ou d’enlever vos données personnelles, tel que quand vous retirez votre consentement. Si nous avons partagé vos données personnelles avec d’autres, nous les aviserons de l’effacement où c’est possible. Si vous nous demandez, et où c’est possible et légal de le faire, nous vous dirons également avec qui nous avons partagé vos données personnelles pour que vous puissiez les contacter directement.
                          </li>
                          <li>
                          <strong>Droit de limiter le traitement:</strong> Vous pouvez nous demander de limiter ou de “bloquer” le traitement de vos données personnelles dans certaines circonstances, tel que quand vous contestez l’exactitude des données ou vous opposez notre traitement de ces données. Nous allons vous aviser avant de lever n’importe quelles restrictions liées au traitement. Si nous avons partagé vos données personnelles avec d’autres, nous les aviserons de cette restriction où c’est possible. Si vous nous demandez, et où c’est possible et légal de le faire, nous vous dirons également avec qui nous avons partagé vos données personnelles pour que vous puissiez les contacter directement.
                          </li>
                          <li>
                          <strong>Droit de portabilité  des données:</strong> Vous avez le droit de nous demander pour vos données personnelles pour lesquelles vous nous avez donné consentement de les obtenir ou pour lesquelles on nous les a fournies pour des raisons valides en connection avec notre contrat avec vous. Nous vous donnerons vos données personnelles dans un format structuré, couramment utilisé et lisible par machine. Vous pouvez les réutiliser n’importe où ailleurs.
                          </li>
                          <li>
                          <strong>Droit de contester:</strong> À tout moment, vous pouvez nous demander d’arrêter le traitement de vos données personnelles, et nous arrêterons si nous comptons sur un intérêt légitime de traiter vos données personnelles (sauf si nous démontrons des motifs légitimes et impérieux en fonction du traitement) ou si nous traitons vos données personnelles pour des raisons de commercialisation directe.
                          </li>
                          <li>
                          <strong>Droit de retirer son consentement:</strong> Si nous comptons sur votre consentement afin de pouvoir traiter vos données personnelles, vous avez le droit de retirer votre consentement à n’importe quel moment. Ceci n’affectera pas la légalité de notre traitement de vos données avant la réception de votre avis de retirer le consentement.
                          </li>
                          <li>
                          <strong>Droit de déposer plainte auprès d’une autorité de protection des données: </strong> Si vous avez un souci concernant nos pratiques de confidentialité, y compris la façon dont nous avons géré vos données personnelles, vous pouvez le signaler à une autorité de protection des données qui est autorisée à recevoir ces préoccupations.
                          </li>
                        </ul>
                      </li>
                    </ol>
                    <p>
                      Vous pouvez exercez vos droits en nous contactant comme indiqué dans la section “Comment contacter Plusgrade?” ci-dessous.
                    </p>
                  </div>
                }
              />
              {/** Question # 9 */}
              <QuestionSection 
                question = {"Comment Plusgrade protège-t-il les données personnelles?"}
                answer={
                  <div>
                    <p>Plusgrade prend des précautions raisonnables, incluant la mise en oeuvre de procédures appropriées physiques, électroniques et administratives, afin de protéger les données personnelles de la perte, le mauvais usage, l’accès non-autorisé, la divulgation, l’altération et la destruction. Malgré le fait que nous employons des mesures de protection technique, nous ne pouvons garantir la sécurité des données personnelles sur ou transmises via l’Internet.</p>
                  </div>
                }
                learnMoreContent={
                  <div>
                    <p>
                    Nous avons mis en place des fortes mesures de sécurité dans le cadre de nos services aux compagnies de voyage et d’hospitalité que nous travaillons avec (c.-à-d. les “partenaires commerciaux” de Plusgrade). En particulier, nous avons pris les mesures appropriées pour protéger les ressources et actifs des compagnies, incluant les données, l’architecture d’application, le code applicatif, l’accès au réseau, l’accès à la localisation et l’accès au hardware/appareil. Nous avons aussi pris les mesures appropriées pour assurer la délivrance et l’accès des données personnelles dans un temps opportun en cas d’un incident physique ou technique, ainsi que implémenté des processus internes afin de pouvoir régulièrement examiner, juger et évaluer l’efficacité des mesures sécuritaires.
                    </p>
                  </div>
                }
              />
              {/** Question # 10 */}
              <QuestionSection 
                question = {"Comment sont ces Règles de Confidentialité imposées à Plusgrade?"}
                answer={
                  <div>
                    <p>
                    Plusgrade suit un processus de surveillance de conformité interne pour assurer la conformité avec ces Règles de Confidentialité. Nous vérifions périodiquement sa précision, compréhensif pour l’information que l’on doit couvrir, affiché de manière visible, complètement exécuté et accessible et en conformité avec les règles de vie privée et de protection des données applicables à Plusgrade.
                    </p>
                  </div>
                }
                learnMoreContent={
                  <div>
                    <p>
                      Nous allons réviser les informations dans ces Règles de Confidentialité sur une base continue et assurer qu’elles restent pertinentes aux pratiques de l’organisation et conforme avec les imminentes exigences réglementaires de confidentialité et de protection des données. Occasionnellement, nous pouvons aussi, à notre discrétion, faire des changements à ces Règles de Confidentialité.
                    </p>

                    <p>
                      Quand nous apportons des changements substantiels à ces Règles de Confidentialité, nous fournirons aux individuels un avis important selon les circonstances et conformément à la loi applicable. Ces avis peuvent être sous la forme d’un courriel ou d’un avis évident sur notre site web. L’utilisation continue de nos services après cet avis constituera une acceptation des changements.
                    </p>
                  </div>
                }
              />
              {/** Question # 11 */}
              <QuestionSection 
                question = {"Comment contacter Plusgrade?"}
                answer={
                  <div>
                    <p>
                    Plusgrade agit en tant que processeur de données pour le traitement des données personnelles des voyageurs de la part des compagnies dans l’industrie de voyage (c.-à-d. les “partenaires commerciaux” de Plusgrade), conformément à notre contrat de service avec le partenaire commercial applicable.
                    </p>
                    <p>
                    Nous agissons en tant que contrôleur de données pour le traitement des données personnelles à propos de nos partenaires commerciaux, prospects et visiteurs du Site pour les buts décrites dans ces Règles de Confidentialité.
                    </p>
                    <p>
                    Toutes questions, commentaires ou plaintes concernant nos Règles de Confidentialité ou nos pratiques de traitement de données peuvent être envoyés directement par la poste ou par courriel au délégué à la protection des données de Plusgrade: 
                    </p>
                    <a href="mailto:dpo@plusgrade.com">dpo@plusgrade.com</a>
                    <p>
                      Toutes questions, commentaires ou plaintes concernant nos Règles de Confidentialité ou notre collection de données et pratiques de traitement concernant des données de sujet UE peuvent être envoyés par la poste ou par courriel au représentant basé à l’UE de Plusgrade:
                    </p>
                  </div>
                }
                learnMoreContent={
                  <div>
                    <p>
                      Si vous sentez que vous n’avez pas reçu une réponse en temps opportun ou satisfaisante de notre part par rapport à vos questions ou plaintes, veuillez s’il-vous-plaît contacter l’autorité de la protection des données applicable ci-dessous:
                    </p>
                    <a href="http://ec.europa.eu/newsroom/article29/item-detail.cfm?item_id=612080">EU Data Protection Authorities (DPAs)</a>
                    <a href="https://www.edoeb.admin.ch/edoeb/en/home/deredoeb.html">Swiss Federal Data Protection and Information Commissioner (FDPIC)</a>
                    <a href="https://www.priv.gc.ca/en">Office of the Privacy Commissioner of Canada</a>
                  </div>
                }
              />
            </div>
          </Copy>
        </PrivacyPolicy>
      </Content>
  </Layout>
  );
  
}

export default PrivacyPage;

const QuestionSectionContainer = styled.div`
font-family: ${font.family.sherika};
pointer-events: none;
p {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0 10px;
  font-size: 24px;
  font-weight: 700;
  color: ${brand.colors.pg_blue};
  margin-bottom: 10px;
  pointer-events: all;
  cursor: pointer;
  border-bottom: 1px solid #BDC4D1;
  padding-bottom: 10px;

  .expand-icon {
    color: #BDC4D1;
    font-size: 40px;
  }
}

.reveal-container {
  max-height: 0;
  height: auto;
  overflow: hidden;
  border: none;
  width: 95%;
  margin: 0 auto 20px;
   p {
    display: block;
    font-size: 20px;
    font-weight: 400;
    color: ${brand.colors.pg_primary_dark};
    margin: 10px 0;
  }

  .italized {
    font-style: italic;
    font-size: 20px;
  }

  a {
    color: ${brand.colors.pg_blue} !important;
    text-decoration: underline;
    pointer-events: all;
  }

  ol {
    margin-left: 1.75rem;
    margin-bottom: 20px;
    li {
      color: ${brand.colors.pg_blue};
      list-style: none;
      font-weight: 800;
      margin: 10px 0;
      font-size: 20px;

      ul > li {
        color: ${brand.colors.pg_primary_dark};
        font-weight: 400;
        font-size: 20px;
      }

      ul > li:before {
        content: '';
      }
    }

    li:before {
      content: counter(list, lower-alpha) ") ";
      counter-increment: list;
      font-size: 20px;
    }
  }
  ul {
    margin-left: 1.75rem;
    margin-bottom: 20px;
    li {
      color: ${brand.colors.pg_primary_dark};
      list-style: circle;
    }

    ul > li {
      list-style: square;
    }
  }
}

${props => props.open === true && css`
  p {
    border: none;
  }
  .reveal-container {
    max-height: 6000px;
  }
`}

`;

const LearnMoreSection = styled.div`
  .learn-more-clickable {
    color: ${brand.colors.pg_blue} !important;
    text-decoration: underline;
  }
  .learn-more-expandable {
    color: ${brand.colors.pg_primary_dark};
    height: auto;
    max-height: 0;
    width: 95%;
    margin: auto;

    a {
      display: block;
      pointer-events: all;
      cursor: pointer;
      text-decoration: underline;
      color: ${brand.colors.pg_blue};
    }
  }

  ${props => props.open === true && css`
    .learn-more-expandable {
      max-height: 6000px;
    }
  `}
`;



{/**
Question Section Component
Accepts
- Question prop = Clickable Header section question
- Answer Prop = Content of dropdown, accepts HTML. Check styled component here carefully as adding p, ul, or ol are pre-styled.
- learnMoreContent = Dropdown content of the 'learn more' section
*/}
const QuestionSection = ({question, answer, learnMoreContent}) => {

const [FAQOpen, setFAQSection] = useState(false);
const [learnMoreOpen, setLearnMore] = useState(false); 

  const pageQuery = useStaticQuery(graphql`
  query{
    plusIconGrey: file(base: {eq: "plus-grey.png"}){
      childImageSharp{
        fixed(width: 32, height: 32, webpQuality: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }

    minusIconGrey: file(base: {eq: "minus-grey.png"}){
      childImageSharp{
        fixed(width: 32, height: 32, webpQuality: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }

  }
`);

let plusIcon = <span className={"expand-icon"}>+</span>
let minusIcon = <span className={"expand-icon"}>-</span>
const openClose = () => {
  if (FAQOpen && learnMoreOpen){
    setLearnMore(false);
  }
  setFAQSection(!FAQOpen); 
}

const expandCompressLearnMore = () => {
  setLearnMore(!learnMoreOpen);
}
  return(
    <QuestionSectionContainer open={FAQOpen}>
      <p onClick={openClose}>{question} {FAQOpen ? minusIcon : plusIcon}</p>
      <div className={"reveal-container"}>
        {answer}
      {
        learnMoreContent ? 
        <LearnMoreSection open = {learnMoreOpen}>
          <p className={"learn-more-clickable"} onClick={expandCompressLearnMore}>Apprendre Plus</p>
          <div className={"learn-more-expandable"}>
            {learnMoreContent}
          </div>
      </LearnMoreSection>
      :
      <></>
      }
      </div>
    </QuestionSectionContainer>
  );
}
